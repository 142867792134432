import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src23513757/src/GardenRoute.store/components/display/WidgetBar/index.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src23513757/src/GardenRoute.store/components/layout/LocalStoragePersistor.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalContextProvider"] */ "/codebuild/output/src23513757/src/GardenRoute.store/contexts/GlobalContext.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src23513757/src/GardenRoute.store/contexts/NextAuthProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src23513757/src/GardenRoute.store/node_modules/keen-slider/keen-slider.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src23513757/src/GardenRoute.store/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src23513757/src/GardenRoute.store/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":\"../styles/fonts/Inter-VariableFont_slnt,wght.ttf\",\"display\":\"swap\"}],\"variableName\":\"Inter\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src23513757/src/GardenRoute.store/public/favicon.ico");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src23513757/src/GardenRoute.store/styles/globals/global.scss");
