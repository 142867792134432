"use client"

import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { usePathname } from "next/navigation";
import { useEffect } from "react";


const queryClient = new QueryClient({
    defaultOptions: {
        queries: { 
            gcTime: 1000 * 60 * 60 * 24
        },
    },
})


function LocalStoragePersister({ children }){
    const pathName = usePathname()

    useEffect(() => {
        const localStoragePersister = createSyncStoragePersister({
            storage: window.localStorage,
            key: "GRS_Persisted",
            throttleTime: 1000
        })

        persistQueryClient({
            queryClient,
            persister: localStoragePersister,
          })

    }, [ pathName ])


    return(
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}


export default LocalStoragePersister;